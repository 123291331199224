import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import  questionSlice  from '../features/FormDesigner/Questions/QuestionSlice';
import userSlice from '../features/User/UserSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    question: questionSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
