import { makeStyles, Theme } from '@material-ui/core';

const ContactStyle = makeStyles((theme: Theme) => ({
    formField: {
        textAlign: 'right',
        fontWeight: 300,
        fontSize: 13,
    },
    formInputs: { paddingTop: '0px !important' },
    formcontrol: {
        width: '100%'
    },
    checkOptions: {
        padding: 5 
    },
    radioOption: {
        fontSize: 13,
    },
    
}));

export default ContactStyle;
