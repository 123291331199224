import { makeStyles, Theme } from '@material-ui/core';

const ContactStyle = makeStyles((theme: Theme) => ({
    formField: {
        fontWeight: 300,
        fontSize: 13,
    },
    skipLogicBox: {
        margin: 20
    },
    formInputs: { paddingTop: '0px !important' },
    checkOptions: {
        padding: 5 
    },
    radioOption: {
        fontSize: 13,
    },
    sectionMsg: {
        fontWeight: 400,
        fontSize: 13,
        borderBottom: '2px solid #e9edf0',
        color: '#607d8b'
    },
    quesDropdown: {
        width: '100%',
    },
    deleteIcon: {
        color: 'red',
    }
    
}));

export default ContactStyle;
