import { Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip } from '@material-ui/core';
import React, { SyntheticEvent, useRef } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuestionItem from '../Questions/QuestionItem';
import Style from './Style';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { GROUP_QUESTION } from '../QuesTypes/Constants';
import { useDispatch } from 'react-redux';
import { rearrange, updateGroup, selectQuestion, deleteGroup, closeSettingsTab } from '../Questions/QuestionSlice';
import { deepClone } from '../../../helpers/utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const SortableItem = SortableElement((item: any) => {
    return item.value.type === GROUP_QUESTION
        ? (
            <GroupComponent value={item.value}>
                <DragHandle />
            </GroupComponent>
        ) : (
            <QuestionItem value={item.value} >
                <DragHandle />
            </QuestionItem>
        )
});

export default function GroupComponent(props: any) {
    const classes = Style();
    const dispatch = useDispatch();
    const wrapperRef = useRef<any>(null);
    let shouldDropQuestion: any = useRef<any>(null);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        console.log(shouldDropQuestion);
        dispatch(closeSettingsTab({}));
        dispatch(rearrange({ oldIndex, newIndex, path: `${props.value.path}, 0` }));
    }

    const toggleAccordion = () => {
        const group = deepClone(props.value);
        group.expanded = !group.expanded;
        dispatch(updateGroup(group));
    }

    const SortableList = SortableContainer(({ items }: any) => {
        const classes = Style();
        return (
            <ul className={classes.questionContainer}>
                {items.map((value: any, index: any) => (
                    <SortableItem key={`g-${index}-${value.type}`} value={{ ...value, path: `${props.value.path},${index}`, index, }} index={index} />
                ))}
            </ul>
        );
    });

    const onEditClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        dispatch(selectQuestion(props.value));
    }

    const onDeleteClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        const result = confirm('Are you sure you want to delete the group ?');
        if (result) dispatch(deleteGroup(props.value));
    }

    const onSortMove=(e: any)=>{
        if(wrapperRef.current ) {
            shouldDropQuestion = !wrapperRef.current.contains(e.target);
        }
    }

    return (
        <div className={classes.groupSection} id={`#g-${props.value.path}`} ref={wrapperRef}>
            <Accordion
                expanded={props.value.expanded}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                    onClick={toggleAccordion}
                    className={classes.accordionSummary}
                >
                    {props.children}
                    <div className={classes.innerSummary}>
                        <Typography className={classes.groupTextColor}>{props.value.name || 'Group'}</Typography>
                        <div className={classes.iconSection}>
                            <Tooltip title="Delete Group" placement="top" arrow>
                                <DeleteOutlineIcon className={classes.groupIcons} onClick={onDeleteClick} />
                            </Tooltip>
                            <Tooltip title="Edit Group" placement="top" arrow>
                                <EditIcon className={classes.groupIcons} onClick={onEditClick} />
                            </Tooltip>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ width: '100%' }}>
                    <SortableList 
                        items={props.value.questions} 
                        lockAxis="y" 
                        onSortEnd={onSortEnd} 
                        onSortMove={onSortMove} 
                        useDragHandle={true} 
                        lockToContainerEdges={true}
                        />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}