import { makeStyles, Theme } from '@material-ui/core';

const ContactStyle = makeStyles((theme: Theme) => ({
    settingsSection: { 
        borderLeft: '1px solid #E0E0E0', 
        height: '500px',
        position: 'sticky',
        top:45,
    },
    questionListSection: {
         backgroundColor: '#fafafa', 
    },
    toolSection: {
        top: 0,
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 1,
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
        border: '1px solid lightgrey',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rightSideButtons: {
        marginLeft: '12px'
    },
    container: {
         padding: 30,
         position: 'relative',
         width: '99%',
    },
    projectTitle: {
        color: '#607d8b',
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default ContactStyle;
