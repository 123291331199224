
import { FormControl, Grid } from '@material-ui/core';
import React from 'react';
import Style from './Style';
import TextField from '@material-ui/core/TextField';

export default function ValidationCriteria(props: any) {
    const classes = Style();

    const onNestedQuestionChange = (e: any, parent: any, child: any) => {
        const event: any = {
            target: {
                name: parent,
                value: {
                    [child]: e.target.value,
                    ['bangla']: e.target.value,
                }
            }
        }
        props.onChange(event);
    }

    console.log('validation criteria props', props);

    return (
        <Grid container spacing={2} style={{ backgroundColor: '#fafafa', marginLeft: 2, width: '99%' }} >
            <Grid item xs={3} className={classes.formField}>
                Validation Code :
            </Grid>
            <Grid item xs={8} className={classes.formInputs}>
                <FormControl className={classes.formcontrol}>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="validationCode"
                        onChange={props.onChange}
                        value={props.question.validationCode}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3} className={classes.formField}>
                Error Message :
            </Grid>
            <Grid item xs={8} className={classes.formInputs}>
                <FormControl className={classes.formcontrol}>
                    <TextField
                        id="outlined-multiline-static"
                        name="errorMsg"
                        onChange={(e) => onNestedQuestionChange(e, 'requiredMessage', 'english')}
                        multiline
                        rows={4}
                        value={props.question.requiredMessage.english}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}