import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Style from '../Style';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { deepClone } from '../../../../helpers/utils';
import { CALCULATE } from '../../QuesTypes/Constants';
import { NO, YES, CUSTOM_LOGIC } from '../constants';

export default function QuestionOptions(props: any) {
    const classes = Style();
    const appearanceOptions = ['minimal', 'quick', 'horizontal-compact', 'horizontal', 'likert', 'compact', 'quickcompact', 'label', 'list-nolabel', 'other'];

    const addOption = () => {
        const opt = deepClone(props.question.options);
        opt.push({ option: '', value: '' });
        props.onChange({ ...props.question, options: opt });
    }

    const removeOption = (index: any) => {
        const opt = deepClone(props.question.options);
        opt.splice(index, 1);
        props.onChange({ ...props.question, options: opt });
    }

    const onQuestionChange = (e: any) => {
        props.onChange({ ...props.question, [e.target.name]: e.target.value });
    }

    const onNestedQuestionChange = (e: any, parent: any, child: any) => {
        props.onChange({ ...props.question, [parent]: { [child]: e.target.value, ['bangla']: e.target.value } });
    }

    const optionValueChange = (index: number, e: any) => {
        const tempOptions = deepClone(props.question.options);
        let opt = tempOptions[index];
        opt = { ...opt, [e.target.name]: e.target.value };
        tempOptions[index] = opt;
        console.log({ tempOptions });
        props.onChange({ ...props.question, options: tempOptions });
    }

    const getLabelOrCaculation = () => {
        if (props.question.type === CALCULATE) {
            return (
                <>
                    <Grid item xs={4} className={classes.formField}>
                        Calculation:
                    </Grid>
                    <Grid item xs={7} className={classes.formInputs}>
                        <Input
                            name="calculation"
                            value={props.question.calculation}
                            className={classes.inputSize}
                            onChange={onQuestionChange}
                        />
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Grid item xs={4} className={classes.formField}>
                        Label <span className={classes.requiredField}>*</span>   :
                    </Grid>
                    <Grid item xs={7} className={classes.formInputs}>
                        <Input
                            name="label"
                            value={props.question.label.english}
                            className={classes.inputSize}
                            onChange={(e) => onNestedQuestionChange(e, 'label', 'english')}
                        />
                    </Grid>
                </>
            );
        }
    }

    return (
        <Grid container spacing={2} className={classes.quesOptionSection}>
            <Grid item xs={4} className={classes.formField}>
                Name <span className={classes.requiredField}>*</span> :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Input
                    name="name"
                    value={props.question.name}
                    className={classes.inputSize}
                    onChange={onQuestionChange}
                />
            </Grid>
            {getLabelOrCaculation()}
            <Grid item xs={4} className={classes.formField}>
                Guidance Hint :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Input
                    name="hint"
                    value={props.question.hint}
                    className={classes.inputSize}
                    onChange={onQuestionChange} />
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                Mandatory Response :
            </Grid>
            <Grid item xs={7} >
                <RadioGroup
                    aria-label="gender"
                    name="mandatory"
                    className={classes.radioOption}
                    onChange={onQuestionChange}
                    value={props.question.mandatory}
                >
                    <FormControlLabel value={YES} control={<Radio />} label="Yes" />
                    <FormControlLabel value={NO} control={<Radio />} label="No" />
                    <FormControlLabel value={CUSTOM_LOGIC} control={<Radio />} label="Custom Logic" />
                </RadioGroup>
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                Default Response :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Input
                    name="defaultResponse"
                    value={props.question.defaultResponse}
                    className={classes.inputSize}
                    onChange={onQuestionChange} />
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                Appearance :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Select
                    labelId="select-appearance"
                    id="select-appearance"
                    name="appearance"
                    value={props.question.appearance}
                    className={classes.inputSize}
                    onChange={onQuestionChange}
                >
                    {appearanceOptions.map((data: any, i: any) => <MenuItem key={i} value={data}>{data}</MenuItem>)}

                </Select>
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                HXL :
            </Grid>
            <Grid item xs={3}>
                <Input placeholder="#tag"
                    name="tag"
                    value={props.question.tag}
                    onChange={onQuestionChange} />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="tagDescription"
                    value={props.question.tagDescription}
                    onChange={onQuestionChange} />
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                Parameters :
            </Grid>
            <Grid item xs={8} className={classes.formInputs}>
                <Checkbox
                    name="parameters"
                    checked={props.question.parameters}
                    onChange={() => props.onChange({ ...props.question, parameters: !props.question.parameters })}

                />
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                Seed :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Input name="seed" value={props.question.seed} onChange={onQuestionChange} className={classes.inputSize} />
            </Grid>
            <Grid item xs={4} className={classes.formField}>
                List Name :
            </Grid>
            <Grid item xs={7} className={classes.formInputs}>
                <Input name="list_name" value={props.question.list_name} onChange={onQuestionChange} className={classes.inputSize} />
            </Grid>
            <Grid container spacing={2}>
                {props.question.options && props.question.options.map((data: any, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <Grid item xs={2} className={classes.formField}>
                                <DeleteForeverIcon style={{ color: 'red' }} onClick={() => removeOption(i)} />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    value={data.option}
                                    name="option"
                                    InputProps={{
                                        classes: {
                                            input: classes.checkOptions
                                        }
                                    }}
                                    onChange={(e: any) => optionValueChange(i, e)}
                                    variant="outlined"
                                />

                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    style={{ padding: '0px' }}
                                    value={data.value}
                                    id="outlined-start-adornment"
                                    name="value"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"> <span style={{ color: 'skyblue', fontSize: 12 }}>XML VALUE</span> </InputAdornment>,
                                        classes: {
                                            input: classes.checkOptions
                                        }
                                    }}
                                    onChange={(e: any) => optionValueChange(i, e)}
                                    variant="outlined"
                                />
                            </Grid>
                        </React.Fragment>
                    )
                })}
                <Grid item xs={12} className={classes.formField}>
                    {props.question.options && (
                        <Button variant="outlined" color="primary" style={{ width: '83%' }} onClick={addOption}>
                            <AddIcon /> Click to add another response
                        </Button>
                    )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}