import { Button, Grid } from '@material-ui/core';
import React from 'react';
import Style from './Style';
import AddIcon from '@material-ui/icons/Add';
import LogicItem from './LogicItem';
import { GROUP_QUESTION } from '../../QuesTypes/Constants';
import { useSelector } from 'react-redux';
import { deepClone } from '../../../../helpers/utils';
import { skipLogicDefaultValue } from './utils';


function getPreviousQuestions(questionArray: any[], len: number): any[]{

    let prevQuestions: any = [];
    for(let i=0; i<len; i++) {

        if(questionArray[i].type === GROUP_QUESTION) 
            prevQuestions = prevQuestions.concat(getPreviousQuestions(questionArray[i].questions, questionArray[i].questions.length));
        else
            prevQuestions.push( questionArray[i]);
    }
    return prevQuestions;
}


export default function SkipLogic(props: any) {
    const classes = Style();
    const questions = useSelector((state: any)=> state.question.questions);
    const values = ['v1', 'v2', 'v3'];
    
    const getQuestions=()=>{
        let path = props.question.path;
        path = path.split(',');
        let filteredQuestions: any[] = [];
        const pathLen = path.length;
        console.log('in the getquestions', {questions});
        for(let i=0; i<pathLen ;i++) {

            if(i === 0) filteredQuestions = filteredQuestions.concat(getPreviousQuestions(questions, parseInt(path[i])));
            else filteredQuestions = filteredQuestions.concat(getPreviousQuestions(questions[path[i-1]].questions, parseInt(path[i])));
        }

        console.log({filteredQuestions});
        return filteredQuestions;

    }

    const addLogic = () => {
        const ques = deepClone(props.question);
        ques.logicList = [...ques.logicList, skipLogicDefaultValue];
        props.onLogicChange(ques);
    }

    const updateLogic = (index: number, data: any) => {
        console.log(index, data);
        const ques:any = deepClone(props.question);
        ques.logicList.splice(index, 1, data);
        props.onLogicChange(ques);
    }

    const removeLogic = (index: number) => {
        const ques = deepClone(props.question);
        ques.logicList.splice(index, 1);
        props.onLogicChange(ques);
    }
    console.log('question in skiplogic, ', props.question);

    return (
        <Grid container spacing={2} className={classes.skipLogicBox}>
            <Grid item className={classes.sectionMsg}>
                This question will only be displayed if the following conditions apply
            </Grid>
            {
                props.question.logicList.map((data: any, i: number) =>
                    <LogicItem
                        key={i}
                        index={i}
                        logic={data}
                        questions={getQuestions()}
                        values={values}
                        updateLogic={updateLogic}
                        removeLogic={removeLogic} />)
            }
            <Grid item xs={12} className={classes.formField}>
                <Button variant="outlined" color="primary" style={{ width: '83%' }} onClick={addLogic}>
                    <AddIcon /> Click to add another response
                </Button>
            </Grid>
        </Grid>

    )
}