import { makeStyles, Theme } from '@material-ui/core';
import { ACKNOWLEDGE, AREA, AUDIO, CALCULATE, DATE, DATE_TIME, DECIMAL, MULTIPLE_SELECT, PHOTO, SELECT_ONE, TEXT, BARCODE, NUMBER, FILE, NOTE, POINT, QUESTION_MATRIX, RANGE, RANKING, TIME, VIDEO } from "../QuesTypes/Constants";
import TextIcon from '../../../Icons/text.png';
import SelectOneIcon from '../../../Icons/select-one.png';
import SelectManyIcon from '../../../Icons/select-many.png';
import AcknowledgeIcon from '../../../Icons/acknowledge.png';
import AreaIcon from '../../../Icons/area.png';
import BarcodeIcon from '../../../Icons/barcode.png';
import NumberIcon from '../../../Icons/number.png';
import FileIcon from '../../../Icons/file.png';
import NoteIcon from '../../../Icons/note.png';
import CalculateIcon from '../../../Icons/calculate.png';
import AudioIcon from '../../../Icons/audio.png';
import DateTimeIcon from '../../../Icons/date-time.png';
import DateIcon from '../../../Icons/date.png';
import DecimalIcon from '../../../Icons/decimal.png';
import PhotoIcon from '../../../Icons/photo.png';
import PointIcon from '../../../Icons/point.png';
import QuestionMatrixIcon from '../../../Icons/question-matrix.png';
import RangeIcon from '../../../Icons/range.png';
import RankingIcon from '../../../Icons/ranking.png';
import TimeIcon from '../../../Icons/time.png';
import VideoIcon from '../../../Icons/video.png';

import TextWhiteIcon from '../../../Icons/text-white.png';
import SelectOneWhiteIcon from '../../../Icons/select-one-white.png';
import SelectManyWhiteIcon from '../../../Icons/select-many-white.png';
import AcknowledgeWhiteIcon from '../../../Icons/acknowledge-white.png';
import AreaWhiteIcon from '../../../Icons/area-white.png';
import BarcodeWhiteIcon from '../../../Icons/barcode-white.png';
import NumberWhiteIcon from '../../../Icons/number-white.png';
import FileWhiteIcon from '../../../Icons/file-white.png';
import NoteWhiteIcon from '../../../Icons/note-white.png';
import CalculateWhiteIcon from '../../../Icons/calculate-white.png';
import AudioWhiteIcon from '../../../Icons/audio-white.png';
import DateTimeWhiteIcon from '../../../Icons/date-time-white.png';
import DateWhiteIcon from '../../../Icons/date-white.png';
import DecimalWhiteIcon from '../../../Icons/decimal-white.png';
import PhotoWhiteIcon from '../../../Icons/photo-white.png';
import PointWhiteIcon from '../../../Icons/point-white.png';
import QuestionMatrixWhiteIcon from '../../../Icons/question-matrix-white.png';
import RangeWhiteIcon from '../../../Icons/range-white.png';
import RankingWhiteIcon from '../../../Icons/ranking-white.png';
import TimeWhiteIcon from '../../../Icons/time-white.png';
import VideoWhiteIcon from '../../../Icons/video-white.png';

const quesStyle = makeStyles((theme: Theme) => ({
    questionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '2%',
        padding: '0px !important',
        border: '1px solid #E0E0E0E0',
        backgroundColor: 'white',
        marginTop: 0,
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        '&:hover': {
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        }
    },
    cardStyle: {
        background: '#fafafa',
        transition: 'ease',
        '&:hover': {
            "& $addBoxIcon": {
                display: 'block',
                marginTop: '-24px',
            }
        }
    },
    questionContainer: {
        padding: 0,
    },
    dragStyle: {
        cursor: 'grabbing',
        '&:hover': {
            color: 'blue',
        }
    },
    nameSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '3%',
        padding: '0px !important',
    },
    questionName: {
        margin: 0,
        color: '#607d8b',
    },
    questionHint: {
        margin: 0,
        fontSize: 13,
        color: '#607d8b',
    },
    iconSize: {
        fontSize: 18,
        borderLeft: '1px solid #E0E0E0E0',
        padding: 5
    },
    editIcon: {
        color: '#4CAF50',
        fontSize: 18,
        borderLeft: '1px solid #E0E0E0E0',
        padding: 5
    },
    deleteIcon: {
        color: '#EF5350',
        fontSize: 18,
        borderLeft: '1px solid #E0E0E0E0',
        padding: 5
    },
    settingsIcon: {
        color: '#BDBDBD',
        fontSize: 18,
        borderLeft: '1px solid #E0E0E0E0',
        padding: 5
    },
    copyIcon: {
        color: '#0091ea',
        fontSize: 18,
        borderLeft: '1px solid #E0E0E0E0',
        padding: 5
    },
    formField: {
        textAlign: 'right'
    },
    question: {
        padding: 10
    },
    iconSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    addBoxIcon: {
        display: 'none',
        paddingLeft: 10,

        '&:hover': {
            color: '#4dd0e1',
        }
    },
    iconStyle: {
        margin: 2,
    },
    textStyle: {
        backgroundImage: `url(${TextIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${TextWhiteIcon})`,
        }
    },
    selectoneStyle: {
        backgroundImage: `url(${SelectOneIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${SelectOneWhiteIcon})`,
        }
    },
    selectmultipleStyle: {
        backgroundImage: `url(${SelectManyIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${SelectManyWhiteIcon})`,
        }
    },
    acknowledgeStyle: {
        backgroundImage: `url(${AcknowledgeIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${AcknowledgeWhiteIcon})`,
        }
    },
    areaStyle: {
        backgroundImage: `url(${AreaIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${AreaWhiteIcon})`,
        }
    },
    barcodeStyle: {
        backgroundImage: `url(${BarcodeIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${BarcodeWhiteIcon})`,
        }
    },
    numberStyle: {
        backgroundImage: `url(${NumberIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${NumberWhiteIcon})`,
        }
    },
    fileStyle: {
        backgroundImage: `url(${FileIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${FileWhiteIcon})`,
        }
    },
    calculateStyle: {
        backgroundImage: `url(${CalculateIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${CalculateWhiteIcon})`,
        }
    },
    audioStyle: {
        backgroundImage: `url(${AudioIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${AudioWhiteIcon})`,
        }
    },
    dateStyle: {
        backgroundImage: `url(${DateIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${DateWhiteIcon})`,
        }
    },
    datetimeStyle: {
        backgroundImage: `url(${DateTimeIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${DateTimeWhiteIcon})`,
        }
    },
    decimalStyle: {
        backgroundImage: `url(${DecimalIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${DecimalWhiteIcon})`,
        }
    },
    photoStyle: {
        backgroundImage: `url(${PhotoIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${PhotoWhiteIcon})`,
        }
    },
    pointStyle: {
        backgroundImage: `url(${PointIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${PointWhiteIcon})`,
        }
    },
    questionmatrixStyle: {
        backgroundImage: `url(${QuestionMatrixIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${QuestionMatrixWhiteIcon})`,
        }
    },
    rangeStyle: {
        backgroundImage: `url(${RangeIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${RangeWhiteIcon})`,
        }
    },
    rankingStyle: {
        backgroundImage: `url(${RankingIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${RankingWhiteIcon})`,
        }
    },
    timeStyle: {
        backgroundImage: `url(${TimeIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${TimeWhiteIcon})`,
        }
    },
    videoStyle: {
        backgroundImage: `url(${VideoIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${VideoWhiteIcon})`,
        }
    },
    noteStyle: {
        backgroundImage: `url(${NoteIcon})`,
        '&:hover': {
            backgroundColor: '#0091ea',
            backgroundImage: `url(${NoteWhiteIcon})`,
        }
    }
}));


export const getImageByType = (type: string) => {
    console.log({ type });
    switch (type) {
        case TEXT:
            return TextIcon;
        case SELECT_ONE:
            return SelectOneIcon;
        case MULTIPLE_SELECT:
            return SelectManyIcon;
        case ACKNOWLEDGE:
            return AcknowledgeIcon;
        case AREA:
            return AreaIcon;
        case BARCODE:
            return BarcodeIcon;
        case NUMBER:
            return NumberIcon;
        case FILE:
            return FileIcon;
        case NOTE:
            return NoteIcon;
        case CALCULATE:
            return CalculateIcon;
        case AUDIO:
            return AudioIcon;
        case DATE_TIME:
            return DateTimeIcon;
        case DATE:
            return DateIcon;
        case DECIMAL:
            return DecimalIcon;
        case PHOTO:
            return PhotoIcon;
        case POINT:
            return PointIcon;
        case QUESTION_MATRIX:
            return QuestionMatrixIcon;
        case RANGE:
            return RangeIcon;
        case RANKING:
            return RankingIcon;
        case TIME:
            return TimeIcon;
        case VIDEO:
            return VideoIcon;
        default:
            TextIcon;
    }
}

export default quesStyle;
