import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';



const initialValues = {
    form_id_string: '',
    form_title: '',
}

const validationSchema = yup.object({
    form_id_string: yup
        .string()
        .required('Id is required'),
    form_title: yup
        .string()
        .required('Title is required'),
});


export default function FormDialog(props: any) {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            props.create({ ...values, form_json: [] });
            resetForm();
        },
    });

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Form</DialogTitle>
            <DialogContent>
                <TextField
                    error={(formik.touched.form_id_string == true && formik.errors.form_id_string) ? true : false}
                    autoFocus
                    margin="dense"
                    id="form_id_string"
                    name="form_id_string"
                    label="Form Id"
                    type="text"
                    value={formik.values.form_id_string}
                    onChange={formik.handleChange}
                    fullWidth
                    helperText={formik.touched.form_id_string && formik.errors.form_id_string}
                />
                <TextField
                    margin="dense"
                    id="form_title"
                    name="form_title"
                    label="Form Title"
                    type="text"
                    value={formik.values.form_title}
                    onChange={formik.handleChange}
                    fullWidth
                    error={(formik.touched.form_title == true && formik.errors.form_title) ? true : false}
                    helperText={formik.touched.form_title && formik.errors.form_title}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={(e: any) => formik.handleSubmit(e)} color="primary">
                    Create
                </Button>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
