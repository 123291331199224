import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { getDefaultValueByQuestionType } from "./Service";
import Style from '../Questions/Style';
import { ACKNOWLEDGE, AREA, AUDIO, CALCULATE, DATE, DATE_TIME, DECIMAL, MULTIPLE_SELECT, PHOTO, SELECT_ONE, TEXT, BARCODE, NUMBER, FILE, NOTE, POINT, QUESTION_MATRIX, RANGE, RANKING, TIME, VIDEO } from "./Constants";
import { useDispatch } from "react-redux";
import { add } from '../Questions/QuestionSlice';

export default function QuestionTypes(props: any) {
    const classes: any = Style();
    const dispatch = useDispatch();

    const quesButtons = () => {
        return [
            TEXT,
            SELECT_ONE,
            MULTIPLE_SELECT,
            NUMBER,
            NOTE,
            CALCULATE,
            DATE_TIME,
            DATE,
            DECIMAL,
            PHOTO,
            POINT,
            // QUESTION_MATRIX,
            // RANGE,
            // RANKING,
            // TIME,
            // VIDEO,
            // AUDIO,
            // ACKNOWLEDGE,
            // AREA,
            // BARCODE,
            // FILE,
        ]
    }

    const getClassName = (data: string): string => `${data.toLowerCase().replace('_', '').replace(' ', '')}Style`;

    const questionBtnClick = (type: string) => {
        dispatch(add({
            ...getDefaultValueByQuestionType(type),
            type,
            path: props.currentQustion.path
        }));
        props.close();
    }

    return (
        <Card variant="outlined" >
            <CardContent className={classes.card} >
                {quesButtons().map((data: any, i: number) => {
                    return (
                        <span key={i} className={classes.iconStyle} onClick={() => questionBtnClick(data)}>
                            <img width="80" height="80" className={classes[getClassName(data)]} />
                        </span>
                    )
                })}
            </CardContent>
        </Card>
    )
}