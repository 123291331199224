import React, { useEffect, useState } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import QuestionOption from './Options';
import { Button, Grid } from '@material-ui/core';
import SkipLogic from './SkipLogic';
import ValidationCriteria from './ValidationCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { closeSettingsTab, update } from '../Questions/QuestionSlice';
import Style from './Style';
import clsx from 'clsx';
import { isValid } from './settingsService';


const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(4),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                color: '#1890ff',
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: '#40a9ff',
            },
        },
        selected: {},
    }),
)((props: any) => <Tab disableRipple {...props} />);


export default function QuestionConfig() {
    const classes = Style();
    const [previousQuestion, setPreviousQuestion] = useState<any>(null);
    const [tab, setTab] = React.useState(0);
    const [msg, setMsg] = useState<any>({ severity: 'success', txt: '', show: false });
    const selectedQuestion = useSelector((state: any) => state.question.selectedQuestion);

    const [question, setQuestion] = useState<any>(selectedQuestion);
    const dispatch = useDispatch();
    const showSettingsTab = useSelector((state: any) => state.question.showSettingsTab);

    /**
     * ------------------------------------------------------------------------------------------------------------------------
     * ----these 2 condition will apply if user forget to click the save button to save the configurtation.--------------------
     */
    /** condition 1 */
    if (previousQuestion !== null
        && question.type !== ''
        && selectedQuestion.type !== ''
        && previousQuestion.path !== selectedQuestion.path) {

        setPreviousQuestion(selectedQuestion);
        dispatch(update(question));
    }

    /** condition 2 */
    if (previousQuestion === null && selectedQuestion.type !== '') {
        console.log('----------->> setting previous question ', { selectedQuestion });
        setPreviousQuestion(selectedQuestion);
    }
    /**
     * ------------------------------------------------------------------------------------------------------------------------
     * ------------------------------------------------------------------------------------------------------------------------
     */

    const handleChange = (event: any, newValue: number) => setTab(newValue);

    const onSettingsChange = (ques: any) => setQuestion({ ...ques });

    const onLogicChange = (ques: any) => setQuestion(ques);

    const setValidationCriteria = (e: any) => setQuestion({ ...question, [e.target.name]: e.target.value });

    useEffect(() => {
        setQuestion(selectedQuestion);
    }, [selectedQuestion]);

    const showMsg = () => {
        setMsg({ severity: 'success', show: true, txt: 'Saved Successfully' });
        setTimeout(() => {
            setMsg({ ...msg, show: false, txt: '' });
            dispatch(closeSettingsTab({}));
        }, 2000);
    }

    const showErrMsg = (txt: string) => {
        setMsg({ severity: 'error', show: true, txt, });
        setTimeout(() => {
            setMsg({ ...msg, show: false, txt: '' });
        }, 2000);
    }

    const updateQuestion = () => {
        const valid: any = isValid(question);
        console.log('update question validation: ', valid);
        if (valid.verdict) {
            dispatch(update(question));
            setPreviousQuestion(null);
            showMsg();
            setTab(0);
        } else {
            showErrMsg(valid.msg);
        }

    }

    const cancel = () => {
        dispatch(closeSettingsTab({}));
        setTab(0);
    }

    return (
        <div
            className={clsx(classes.animatedItem, classes.root, {
                [classes.animatedItemExiting]: !showSettingsTab
            })}
        //   style={{display: showSettingsTab ? 'block' : 'none'}}
        >
            <div className={classes.demo1}>
                <AntTabs value={tab} onChange={handleChange} aria-label="ant example">
                    <AntTab label="Question Options" />
                    <AntTab label="Skip Logic" />
                    <AntTab label="Validation Criteria" />
                </AntTabs>
                <Typography className={classes.padding} />
                {tab === 0 && <QuestionOption question={question} onChange={onSettingsChange} />}
                {tab === 1 && <SkipLogic question={question} onLogicChange={onLogicChange} />}
                {tab === 2 && <ValidationCriteria question={question} onChange={setValidationCriteria} />}
                <Grid item xs={12}>{msg.show && <p className={msg.severity === 'success' ? classes.success : classes.error}>{msg.txt}</p>}</Grid>
                <Grid container justifyContent="center" style={{ padding: 30 }}>
                    <Button variant="contained" color="default" onClick={cancel} style={{ marginRight: 20 }}> Cancel</Button>
                    <Button variant="contained" color="primary" onClick={updateQuestion}> Submit</Button>
                </Grid>
            </div>
        </div>
    );
}
