import React, { SyntheticEvent, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Style, { getImageByType } from './Style';
import { Tooltip } from '@material-ui/core';
import QuestionAddTool from '../../../components/Poper';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { addToMultiSelection, selectQuestion, deleteQuestion, closeSettingsTab } from './QuestionSlice';

const activeStyle = {
    border: '1px solid #2196f3',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    backgroundColor: 'rgb(251, 394, 521)',
};

export default function QuestionItem(props: any) {
    const classes = Style();
    const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
    const [anchor, setAnchor] = useState<any>(null);
    const dispatch = useDispatch();
    const toggleAnchor = () => setAnchorVisibility(false);
    const selectedQuestion = useSelector((state: any) => state.question.selectedQuestion);
    const multiselection = useSelector((state: any) => state.question.multiselection);

    const openAnchor = (event: SyntheticEvent) => {
        setAnchor(event.currentTarget);
        setAnchorVisibility(true);
    }

    const setSelectedQuestion = (e: any) => {
        if (e.ctrlKey) {
            dispatch(addToMultiSelection(props.value.path));
        } else {
            dispatch(selectQuestion(props.value));
        }
    }

    const getCardStyle = () => {
        if (multiselection.onGoing) {
            return (multiselection.pathList.includes(props.value.path)) ? activeStyle : {};
        }
        else {
            return selectedQuestion.path === props.value.path ? activeStyle : {}
        }
    }

    const onDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        const yes = confirm(`Are you sure you want to delete ? \n ${props.value.name}`);
        if (yes) {
            dispatch(deleteQuestion(props.value));
            dispatch(closeSettingsTab({}));
        }
    }

    return (
        <Card elevation={0} className={classes.cardStyle}>
            <CardContent className={classes.questionBox} onClick={setSelectedQuestion} style={getCardStyle()}>
                <div className={classes.nameSection}>
                    {props.children}
                    {
                        props.value.type === ''
                            ? (<p>This form is currently empty. You can add questions, notes, prompts, or other fields by clicking on the + sign below.</p>)
                            : (<img src={getImageByType(props.value.type)} height="60" width="60" />)
                    }
                    <div className={classes.question}>
                        <p className={classes.questionName}>{props.value.label.english}</p>
                        <p className={classes.questionHint}> {props.value.name}</p>
                    </div>
                </div>
                <div className={classes.iconSection}>
                    {props.value.type !== '' && (<>
                        {/* <Tooltip title="Copy Question" placement="right" arrow>
                        <FileCopyOutlinedIcon className={classes.copyIcon} />
                    </Tooltip> */}
                        <Tooltip title="Settings" placement="right" arrow>
                            <SettingsIcon className={classes.settingsIcon} />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right" arrow>
                            <DeleteForeverIcon className={classes.deleteIcon} onClick={onDelete} />
                        </Tooltip>
                        {/* <Tooltip title="Edit" placement="right" arrow>
                        <EditIcon className={classes.editIcon} />
                    </Tooltip> */}

                    </>)}
                </div>
            </CardContent>

            <div id="popover-anchor" onClick={openAnchor} style={{ width: '2%' }}>
                <AddBoxIcon className={classes.addBoxIcon} />
            </div>
            <QuestionAddTool open={anchorVisibility} anchorEL={anchor} toggleAnchor={toggleAnchor} currentQustion={props.value} />
        </Card>
    );
}